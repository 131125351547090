import * as React from 'react'

import Link from 'next/link'
import Image from 'next/future/image'

import { ActiveLink } from '../active-link'
import { Button } from 'components/button'
// @ts-expect-error
import { ReactComponent as Twitter } from '/public/svgs/twitter.svg'

import { block } from 'utils/bem'
import { useScrollLock } from 'utils/use-scroll-lock'
import { useUser } from 'utils/user'
import { shortAddress } from 'utils/short-address'

import styles from './nav-bar.module.scss'

type NavBarProps = {
  semiOpaque?: boolean
  className?: string
}

export type ModalStates =
  | { type: 'login' }
  | { type: 'error'; message?: string }
  | null

export const NavBar = ({ semiOpaque = false, className }: NavBarProps) => {
  const { user, refetchBalances, login } = useUser()

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const [showAstoInfo, setShowAstoInfo] = React.useState(false)

  const { lockScroll, unlockScroll } = useScrollLock()

  React.useEffect(() => {
    if (isMenuOpen) {
      lockScroll()
    } else {
      unlockScroll()
    }
  }, [isMenuOpen, lockScroll, unlockScroll])

  return (
    <header>
      <nav
        aria-label="Navigation menu"
        className={block(
          styles,
          'navbar',
          [isMenuOpen && 'is-open', semiOpaque && 'semiOpaque'],
          className
        )}
      >
        <Link href="/">
          <a className={styles.navbar__logo}>
            <Image
              src="/logos/logo.svg"
              alt="Project Tempus - Home"
              width={161}
              height={47}
              priority
            />
          </a>
        </Link>

        <button
          tabIndex={0}
          className={block(styles, 'hamburger', [isMenuOpen && 'is-open'])}
          aria-controls="menu-list"
          aria-label="menu"
          aria-expanded={isMenuOpen}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          onKeyDown={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className={styles.hamburger__inner} aria-hidden="true">
            <span
              className={styles.hamburger__inner__bar}
              aria-hidden="true"
            ></span>
            <span
              className={styles.hamburger__inner__bar}
              aria-hidden="true"
            ></span>
            <span
              className={styles.hamburger__inner__bar}
              aria-hidden="true"
            ></span>
          </div>
        </button>

        <ul
          id="menu-list"
          className={block(styles, 'menuLinks', [isMenuOpen && 'is-open'])}
        >
          {/* <li>
            <ActiveLink href="/observatory">OBSERVATORY</ActiveLink>
          </li> */}

          <li>
            <ActiveLink href="/bookshelf">BOOKSHELF</ActiveLink>
          </li>
          <li className={styles.hideOnDesktop}>
            <ActiveLink href="/news">NEWS</ActiveLink>
          </li>
          <li className={styles.hideOnDesktop}>
            <ActiveLink href="/help">HELP</ActiveLink>
          </li>

          {!user ? (
            <>
              <li>
                <Button
                  className={styles.loginButton}
                  color="secondary"
                  onClick={() => login()}
                >
                  Login
                </Button>
              </li>
            </>
          ) : (
            <>
              <li>
                <ActiveLink href="/account">ACCOUNT</ActiveLink>
              </li>
              <li>
                <Link href="/account">
                  <a
                    className={styles.wallet}
                    onMouseEnter={() => {
                      refetchBalances()
                      setShowAstoInfo(true)
                    }}
                    onMouseLeave={() => setShowAstoInfo(false)}
                    data-text="title"
                  >
                    {user.eoa !== null && shortAddress(user.eoa)}
                  </a>
                </Link>
              </li>
            </>
          )}

          <li className={styles.hideOnDesktop}>
            <a
              aria-label="Twitter"
              className={styles.link}
              href="https://twitter.com/ProjectTempus_"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter alt="" aria-hidden="true" />
            </a>
          </li>
        </ul>
        <div className={block(styles, 'info', [showAstoInfo && 'hovered'])}>
          <dl>
            <div>
              <Image
                src={'/logos/asto.png'}
                width={18}
                height={15}
                alt="asto logo"
              />

              <div>
                <dt>Asto Balance</dt>
                <dd>{user?.balances?.astoBalance ?? 0}</dd>
              </div>
            </div>
            <div>
              <Image
                src={'/logos/asto.png'}
                width={18}
                height={15}
                alt="tempus logo"
              />

              <div>
                <dt>Asto staked</dt>
                <dd>{user?.balances?.stakedAstoBalance ?? 0}</dd>
              </div>
            </div>
            <div>
              <Image
                src={'/logos/logo-tiny.png'}
                width={16}
                height={16}
                alt="tempus logo"
              />
              <div>
                <dt>Tempus points</dt>
                <dd>{user?.balances?.tempusBalance ?? 0}</dd>
              </div>
            </div>
            {user?.futurepass && (
              <div>
                <Image
                  src={'/logos/futureverse.svg'}
                  width={16}
                  height={16}
                  alt="tempus logo"
                />
                <div>
                  <dt>FuturePass Address</dt>
                  <dd>{shortAddress(user.futurepass)}</dd>
                </div>
              </div>
            )}
          </dl>
        </div>
      </nav>
    </header>
  )
}
